import React from "react"

import { css, Global } from "@emotion/core"

interface Props {
  children?: any
}

const Layout = ({ children }: Props) => {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      `}
    >
      <Global
        styles={css`
          html,
          body,
          main,
          #___gatsby {
            height: 100%;
          }

          body {
            margin: 0px;
          }

          div[role="group"][tabindex] {
            height: 100%;
          }
        `}
      />
      <main>{children}</main>
      <footer
        css={css`
          padding: 20px;
        `}
      >
        © {new Date().getFullYear()}
      </footer>
    </div>
  )
}

export default Layout
